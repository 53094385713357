<template>
  <cleave
    v-model="own_price"
    class="form-control"
    :raw="false"
    :options="options.number"
    :placeholder="placeholder"
    @blur="saveCost"
  />
</template>

<script>
import Cleave from 'vue-cleave-component'
import utils from '@/libs/utils'

export default {
  name: 'InputNumberComma',
  props: {
    placeholder: {
      type: String,
      required: false,
      default: '10,000'
    },
    price: {
      required: true
    },
    identifier: {
      required: true,
      type: String
    }
  },
  components: {
    Cleave
  },
  data () {
    return {
      utils,
      own_price: this.price,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        }
      },
    }
  },
  created () {
  },
  methods: {
    saveCost() {
      if (this.own_price) {
        const new_price = utils.trimNumber(`${this.own_price}`);
        this.$emit('changeValue', new_price, this.identifier)
      } else {
        this.$emit('changeValue', null, this.identifier)
      }
    }
  }
}
</script>

