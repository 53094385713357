<template>
  <b-row >
    <b-col
      class="col-12 col-sm-6 col-md-6 col-lg-3 col-xxl-3"
      v-for="content, index in contents"
      :key="index"
    > 
      <b-card
        no-body
        class="card hover-card card-image-content card-type-content"
      > 
      <b-link :href="getUrl(content)" target="_blank">
        <b-img-lazy v-if="content.content_preview_url" :id="`view${index}`" class="card-image-content" :src="getPreview(content)" @error.native="errorLoad(index)" />
        <b-embed
          v-else-if="content.image_content && utils.isVideo(content.image_content)"
          type="iframe"
          aspect="4by3"
          :src="content.image_content"
          allowfullscreen
          :id="`view${index}`"
        ></b-embed>
      </b-link>
      <b-tooltip :target="`view${index}`" variant="primary" :title="$t('campaigns.watch_content')" custom-class="tooltip-contents-view" />
      <div>
        <interactions
          :index="index"
          :content="content"
          />
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BImgLazy, BLink, BTooltip, BEmbed
} from 'bootstrap-vue'
import service_campaign from "@/services/campaign";
import Interactions from './Interactions.vue';
import utils from '@/libs/utils';


export default {
  name: 'ContentsView',
  components: {
    BRow,
    BCol,
    BCard,
    Interactions,
    BImgLazy,
    BLink,
    BTooltip,
    BEmbed
  },
  data() {
    return {
      utils,
      contents: [],
      backgroundDefault: require("@/assets/images/dashboard/imgDefault.png"),
      interval_loading: null
    }
  },
  created () {
    this.$vs.loading({
      type: 'sound',
      background: 'rgba(255, 255, 255, 1)',
      text: utils.getMotivationalPhrase(),
    });

    this.interval_loading = setInterval(() => {
      const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

      if (prev_loading) {
        prev_loading.classList.add('fade-out');
        setTimeout(function() {
          prev_loading.remove()
        }, 500);

        this.$vs.loading({
          type: 'sound',
          background: 'rgba(255, 255, 255, 1)',
          text: utils.getMotivationalPhrase(),
        });
      }
    }, 5000);
    service_campaign.getCampaignManage(this.$route.params.uuid).then((response) => {
      this.formatContents(response.results)
      clearInterval(this.interval_loading)
      this.$vs.loading.close();
    })
  },
  methods: {
    getUrl(content) {
      if (content.image_content) return content.image_content
      return content.url_info
    },
    getPreview(content) {
      if (content.content_preview_url) return content.content_preview_url
      else return content.image_content
    },
    errorLoad(index) {
      this.contents[index].content_preview_url = this.backgroundDefault;
    },
    getMetrics(content) {
      const metrics = [
        {name: 'number_of_likes', value: content.number_of_likes},
        {name: 'number_of_comments',  value: content.number_of_comments},
        {name: 'number_of_shares', value: content.number_of_shares},
        {name: 'number_of_rts', value: content.number_of_rts},
        {name: 'number_of_times_favs', value: content.number_of_times_favs},
        {name: 'number_of_times_saved', value: content.number_of_times_saved},
        {name: 'number_of_views', value: content.number_of_views},  
        {name: 'number_of_dislikes', value: content.number_of_dislikes},
        {name: 'number_of_clicks_to_stickers', value: content.number_of_clicks_to_stickers},
        {name: 'number_of_clicks_to_the_link', value: content.number_of_clicks_to_the_link},
        {name: 'number_of_reproductions', value: content.number_of_reproductions},
        {name: 'number_of_impressions', value: content.number_of_impressions},
        {name: 'number_of_prints', value: content.number_of_prints},
        {name: 'number_of_responses', value: content.number_of_responses},
        {name: 'scope_number', value: content.scope_number},
        {name: 'number_of_interactions', value: content.number_of_interactions},
      ]
      return metrics.filter((item) => item.value > 0 && item.value !== null)
    },
    getUrlContent(content) {
      if (content.url_info) return content.url_info
      else if (content.image_content) return content.image_content
      return ''
    },
    getNetworkByUrl(url) {
      if (url.includes('instagram.com')) return 'instagram'
      else if (url.includes('spotify.com')) return 'spotify'
      else if (url.includes('twitter.com')) return 'twitter'
      else if (url.includes('facebook.com')) return 'facebook'
      else if (url.includes('youtube.com')) return 'youtube'
      else if (url.includes('twitch.tv')) return 'twitch'
      else if (url.includes('tiktok.com')) return 'tiktok'
      else return 'brandme'
    },
    formatContents(influencers) {
      const data = []
      influencers.forEach(influencer => {
        influencer.content.forEach((content) => {
          const url = this.getUrlContent(content)
          if (url) {
            if (content.content_type !== 'story') 
              content['content_preview_url'] =  content.content_preview_url ? content.content_preview_url : this.backgroundDefault;
            else if (!utils.isVideo(content.image_content)) 
              content['content_preview_url'] =  content.image_content ? content.image_content : this.backgroundDefault;
            else
              content['content_preview_url'] =  content.image_content ? content.content_preview_url : this.backgroundDefault;
            content['influencer'] = influencer.influencer
            content['network_by_url'] = this.getNetworkByUrl(url);
            content['metrics'] = this.getMetrics(content)
            data.push(content)
          }
        })
      })
      this.contents = data;
    },
  }
}
</script>

<style>
.hover-card:hover {
  transform: scale(1.05);
  transition: all 500ms;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}
.card-image-content {
  width: 100%;
  object-fit: cover;
  height: 300px;
  border-radius: 1%;
}
.card-type-content{
 display: flex;
 height: 90%;
}
</style>
