<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <div class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column">
        <calendar-sidebar-influencers :influencers="influencers" @filterEvents="filterEvents" :key="influencers_key"/>
      </div>

      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar
              ref="refCalendar"
              :options="calendarOptions"
              :key="key_calendar"
            />
          </div>
        </div>
      </div>
    </div>

    <sidebar-content
      v-if="Object.keys(event_detail).length !== 0"
      :show_side="show_side"
      :content_dad="event_detail"
      :campaign_influencer="campaign_influencer"
      :campaign_uuid="campaign_uuid"
      @updateFromSidebar="updateFromSidebar"
      :key="change_sidebar"
      class="open-sidebar-contents"
    />
    <b-modal id="detail-event" body-class="p-0" centered hide-header hide-footer>
      <detail-event :event="event_detail"/>
    </b-modal>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import CalendarSidebarInfluencers from './CalendarSidebarInfluencers'
import service from "@/services/campaign"
import manage_campaign from '@/services/manage_campaign'
import utils from '@/libs/utils'
import {
  BModal
} from 'bootstrap-vue'
import DetailEvent from './DetailEvent'
import esLocale from '@fullcalendar/core/locales/es';
import sidebarContent from '@/views/components/campaign/sidebarContent';

export default {
  name: 'CalendarInfluencers',
  components: {
    FullCalendar,
    CalendarSidebarInfluencers,
    BModal,
    DetailEvent,
    sidebarContent
  },
  data() {
    return {
      show_side: false,
      campaign_uuid: this.$route.params.uuid,
      campaign_influencer: '',
      change_sidebar: false,
      influencers_key: false,
      key_calendar: false,
      influencers: [],
      events: [],
      event_detail: {},
      variant_influencers: [
        {type: 'top-influencer-celebrity', variant: 'danger'},
        {type: 'macroinfluencer', variant: 'primary'},
        {type: 'microinfluencer', variant: 'warning'},
        {type: 'nanoinfluencer', variant: 'success'},
        {type: 'top-celebrity', variant: 'info'},
      ],
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
          start: 'sidebarToggle, prev,next, title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
        },
        editable: true,
        eventResizableFromStart: false,
        dragScroll: true,
        dayMaxEvents: 1,
        navLinks: true,
        locale: esLocale,
        eventClassNames: this.getEventClass,
        eventDrop: this.handleEventDrop,
        eventClick: this.handleEventCLick,
        rerenderDelay: 350,
        events: [],
        eventDidMount: (info) => {
          if (info.view.type !== 'listMonth') {
            const container = info.el.querySelector('.fc-event-title-container')
            const data = this.formatEventData(info.event)

            const div = document.createElement('div')
            const icon = document.createElement('i')
            const br = document.createElement('br')
            const img = document.createElement('img')

            div.append(data.extendedProps.description)
            div.setAttribute('class', 'fc-event-title fc-sticky')

            if (data.extendedProps.network === 'twitter') {
              img.setAttribute('src', utils.getClassNetworkSpan(data.extendedProps.network))
              img.classList.add('social-network-calendar-x', 'd-none', 'd-sm-block')
              container.append(img)
            } else {
              icon.setAttribute('class', `${utils.getClassNetworkSpan(data.extendedProps.network)} social-network-calendar d-none d-sm-block`)
              container.append(icon)
            }

            if (info.view.type === 'timeGridDay') {
              icon.classList.add('padding-4')
            }

            container.append(br)
            container.append(div)
          }
        },

      }
    }
  },
  created() {
    service.getCampaignManage(this.$route.params.uuid)
      .then(response => {
        this.influencers = response.results
        const events = this.formatEvents()
        this.calendarOptions.events = events
        this.calendarOptions.locale = this.$i18n.locale === 'es' ? esLocale : this.$i18n.locale
        this.events = events
        this.influencers_key = !this.influencers_key
        const self = this
        setTimeout(() => {
          self.$refs.refCalendar.getApi().changeView('timeGridDay')
          self.$refs.refCalendar.getApi().changeView('dayGridMonth')
        }, 200)
      })
  },
  methods: {
    updateFromSidebar(data) {
      this.show_side = false;
      this.event_detail.status = data.status;
      this.event_detail.url_info = data.url_info;
      this.event_detail.number_of_impressions = data.number_of_impressions;
      this.event_detail.updated_at = data.updated_at;
      this.event_detail.published_date = data.published_date
      this.event_detail.number_of_comments = data.number_of_comments
      this.event_detail.number_of_likes = data.number_of_likes
      this.event_detail.number_of_shares = data.number_of_shares
      this.change_sidebar = !this.change_sidebar;
    },
    formatEventData(data) {
      const {
        id,
        title,
        start,
        end,
        allDay,
        extendedProps: {
          variant,
          type_influencer,
          network,
          username,
          profile_url,
          followers,
          is_verified,
          link_content,
          description
        }
      } = data

      return {
        id,
        title,
        start,
        end,
        extendedProps: {
          variant,
          type_influencer,
          network,
          username,
          profile_url,
          followers,
          is_verified,
          link_content,
          description
        },
        allDay,
      }
    },
    handleEventDrop ({event: clickedEvent}) {
      if (this.disabledUpdateCampaign()) return;

      const data = this.formatEventData(clickedEvent)
      const new_date = new Date(data.start).toISOString().split('T')[0]

      const formData = new FormData()
      formData.append('published_date', new_date)

      manage_campaign.updateContent(this.$route.params.uuid, data.id, formData)
        .then(() => {
        })
    },
    handleEventCLick({event: clickedEvent}) {
      this.event_detail = this.formatSidebar(clickedEvent);
      this.show_side = true;
      this.change_sidebar = !this.change_sidebar;
    },
    getEventClass({event: calendarEvent}) {
      const data = this.formatEventData(calendarEvent)

      return [`bg-light-${data.extendedProps.variant}`,]
    },
    formatEvents() {
      const events = [];
      this.influencers.forEach(influencer => {
        influencer.content.forEach(con => {
          if (con.published_date) {
            const variant = this.getVariant(influencer.influencer)
            const date_end = new Date(con.published_date)
            date_end.setDate(date_end.getDate() + 2)

            const obj = {
              id: con.uuid,
              title: influencer.influencer.name,
              start: con.published_date,
              end: date_end,
              allDay: true,
              icon: '',
              extendedProps: {
                con,
                variant,
                type_influencer: influencer.influencer.calculate_category,
                network: influencer.influencer.network.toLowerCase(),
                username: influencer.influencer.username,
                name: influencer.influencer.name,
                image_url: influencer.influencer.image_url,
                engagement_rate: influencer.influencer.engagement_rate,
                file_for_profile: influencer.influencer.file_for_profile,
                profile_url: !influencer.influencer.file_for_profile ? influencer.influencer.profile_url : (utils.isUrl(influencer.influencer.file_for_profile) ? influencer.influencer.file_for_profile : (utils.getAssetUrl(influencer.influencer.file_for_profile))),
                followers: influencer.influencer.followers,
                is_verified: influencer.influencer.is_verified,
                link_content: con.url_info ? con.url_info : (con.image_content ? con.image_content : null),
                description:  con.url_info ? this.$t('campaigns.onePublsihed') : (con.image_content ? this.$t('campaigns.onePublsihed') : this.$t('campaigns.withoutPublish'))
              }
            }
            events.push(obj)
          }
        })
      })

      return events
    },
    formatSidebar(clickedEvent) {
      const data = clickedEvent._def.extendedProps
      const content = clickedEvent._def.extendedProps.con;
      this.campaign_influencer = content.campaing_influencer;
      content.username = data.username;
      content.category = data.type_influencer;
      content.image_url = data.image_url;
      content.profile_url = data.profile_url;
      content.followers = utils.getFormat(data.followers);
      content.engagement = utils.decimalFixed(data.engagement_rate, 2),
      content.file_for_profile = data.file_for_profile;
      content.network_influencer = data.network.toLowerCase();
      content.name = data.name;
      content.is_verified = data.is_verified
      return content
    },
    getVariant(influencer) {
      const variant = this.variant_influencers.find(item => item.type === influencer.calculate_category)

      if (variant) return variant.variant
      else 'secondary'
    },
    filterEvents(filters) {
      const events = this.events.filter(event => {
        return filters.indexOf(event.extendedProps.type_influencer) >= 0 || filters.indexOf(event.title) >= 0
      })

      this.calendarOptions.events = events
    },
    disabledUpdateCampaign() {
      return !utils.hasPermission('change_campaign')
    },
  },
  watch: {
    '$i18n.locale'() {
      this.calendarOptions.locale = this.$i18n.locale === 'es' ? esLocale : this.$i18n.locale
      this.key_calendar = !this.key_calendar
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";

  .social-network-calendar {
    font-size: 1.3em;
    position: absolute !important;
    left: 90%;
    top: 1%;
    background: white;
    padding: 2%;
    border-radius: 50%;
  }
  .social-network-calendar-x {
    width: 1.6em;
    position: absolute !important;
    left: 92%;
    top: 1%;
    background: white;
    padding: 2%;
    border-radius: 50%;
  }
  .padding-4 {
    left: 10%;
    padding: 4px;
  }
</style>
