<template>
  <b-modal 
    body-class="select-campaign-modal"  
    @hidden="CloseModalNetwork"
    centered 
    hide-footer 
    hide-header 
    v-model="show"
    :size="size">
    <div :class="`header-select-campaign ${padding} ${bg_title}`" >
      <feather-icon
          icon="XIcon"
          size="24"
          :class="closeIcon"
          @click="show = !show"
      />
      <slot name="title"></slot>
    </div>
    <div :class="`${bodyClass} ${bg_body}`">
      <slot name="body"></slot>
    </div>

    <div :class="`${class_footer} ${bodyClass}`">
      <slot name="footer"></slot>
    </div>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';

export default {
  name: 'ModalBrandme',
  components: {
    BModal,
  },
  data() {
    return {
      show: this.show_modal,
    }
  },
  methods: {
    CloseModalNetwork() {
      this.$emit('hidden')
    },
  },
  props: {
    class_footer: {
      type: String,
      default: 'class-footer-bm'
    },
    bg_body: {
      type: String,
      default: 'class-bg-body-bm'
    },
    bg_title: {
      type: String,
      default: 'class-bg-header-bm'
    },
    show_modal: {
      type: Boolean,
      required: true
    },
    padding: {
      type: String,
      default: 'p-1'
    },
    size: {
      type: String,
      default: 'md'
    },
    closeIcon: {
      type: String,
      default: 'close-select-campaign-modal'
    },
    bodyClass: {
      type: String,
      default: 'p-1'
    }
  },
}
</script>
<style>
.class-footer-bm {
  background-color: white;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.class-bg-body-bm {
  background-color: white;
}
.class-bg-header-bm {
  background-color: white;
}
.select-campaign-modal {
  padding: 0px !important;
}
.close-select-campaign-modal {
  display: block;
  color: rgb(155, 155, 155);
  margin: 0 1.3em 0 0;
}
.close-select-campaign-modal:hover {
  background-color: rgba(219, 219, 219, 0.5);
  border-radius: 50%;
  cursor: pointer;
}
.header-select-campaign {
  padding: 1em;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  align-items: center
}
.header-select-campaign span {
  font-size: 18px;
}
</style>