import config from '@/services/config'
import axios from 'axios'
import {tokenExpired} from '@/auth/utils'

export default {
  getCampaign (campaign_uuid) {
    return axios.get(`${config.urlCore}/api/v1/campaign/${campaign_uuid}`, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  getCampaignBrief (campaign_uuid) {
    return axios.get(`${config.urlCore}/api/v1/campaign/${campaign_uuid}`, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  getCampaignInfluencers(campaign_uuid, page = 1, status = 'all', params = '') {
    let url = `${config.urlCore}/api/v1/campaign/${campaign_uuid}/influencers/?page=${page}&size_page=8${params}`
    if (status !== 'all') url += `&status=${status}`

    return axios.get(url, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  getCampaignManage(campaign_uuid) {
    return axios.get(`${config.urlCore}/api/v1/campaign/${campaign_uuid}/manage/`, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  influencerAction(campaign_uuid, influencers, status) {
    const data = { influencers, status}
    return axios.post(`${config.urlCore}/api/v1/campaign/${campaign_uuid}/influencers/`,
      data,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  getCampaignBrands () {
    return axios.get(`${config.urlCore}/api/v1/brands/`, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  getMessageInvitation () {
    return axios.get(`${config.urlCore}/api/v1/campaign/message/invitation/`, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  createCampaign (campaign) {
    return axios.post(`${config.urlCore}/api/v1/campaign/`,
      campaign,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  addInfluncer (campaign_uuid, formdata) {
    return axios.post(`${config.urlCore}/api/v1/campaign/${campaign_uuid}/assign/`,
      formdata,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  addInfluencersCampaign(campaign_uuid, influencers) {
    const data = {influencers}
    return axios.post(`${config.urlCore}/api/v3/campaign/${campaign_uuid}/massive/assign/`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response.data.response
      })
  },
  addInfluncerv3 (campaign_uuid, formdata) {
    return axios.post(`${config.urlCore}/api/v3/campaign/${campaign_uuid}/assign/`,
      formdata,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  getStats (campaign) {
    return axios.get(`${config.urlCore}/api/v1/campaign/${campaign}/manage/stats/`, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  getNewStats (campaign) {
    return axios.get(`${config.urlCore}/api/v3/campaign/${campaign}/manage/stats/`, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response.data.response
      })
  },
  getNewStatsDemography (campaign) {
    return axios.get(`${config.urlCore}/api/v3/campaign/${campaign}/manage/stats/demography/`, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response.data.response
      })
  },
  getNewStatsAges (campaign) {
    return axios.get(`${config.urlCore}/api/v3/campaign/${campaign}/manage/stats/ages/`, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response.data.response
      })
  },
  createCustomField(camapign_uuid, form_data) {
    return axios.post(`${config.urlCore}/api/v1/campaign/${camapign_uuid}/custom_field/`,
      form_data, {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response.data.response
      })
  },
  updateCampaignField (campaign, field, formdata) {
    return axios.post(`${config.urlCore}/api/v1/campaign/${campaign}/custom_field/${field}/`,
      formdata, {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  reorderCustomFields(campaign_pk, custom_fields) {
    return axios.post(`${config.urlCore}/api/v1/campaign/reorder/custom-fields/${campaign_pk}/`,
      {custom_fields}, {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  updateCampaign (campaign, formdata) {
    return axios.post(`${config.urlCore}/api/v1/campaign/${campaign}/update/`,
      formdata, {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  deleteCustomField(campaign_uuid, custom_field_uuid) {
    return axios.delete(`${config.urlCore}/api/v1/campaign/${campaign_uuid}/custom_field/${custom_field_uuid}/`, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  addContent(campaign_uuid, formdata) {
    return axios.post(`${config.urlCore}/api/v1/campaign/${campaign_uuid}/manage/add/`,
      formdata, {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  bulkAddContent(campaign_uuid, formdata) {
    return axios.post(`${config.urlCore}/api/v1/campaign/${campaign_uuid}/manage/bulk-add/`,
      formdata, {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  exportProposals(campaign_uuid, campaign_influencers) {
    const data = {campaign_influencers}
    return axios.post(`${config.urlCore}/api/v1/campaign/${campaign_uuid}/export/proposals/`, 
      data, {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  exportManage(campaign_uuid) {
    return axios.get(`${config.urlCore}/api/v1/campaign/${campaign_uuid}/export/manage/`, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    }).then(response => response.data)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  checkProcess() {
    return axios.get(`${config.urlCore}/api/v1/campaign/check/process/`, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  uploadCsv (campaign_uuid, formData) {
    return axios.post(`${config.urlCore}/api/v1/campaign/${campaign_uuid}/import/influencers/`,
      formData,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response.data.response
      })
  },
  deleteCreator (campaign_uuid, formData) {
    return axios.post(`${config.urlCore}/api/v1/campaign/${campaign_uuid}/influencers/`,
      formData,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response.data.response
      })
  },
  getInfluencersCampaign (campaign_uuid) {
    return axios.get(`${config.urlCore}/api/v1/campaign/${campaign_uuid}/influencers/`,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response.data.response
      })
  },
}
