<template>
  <div>
  <b-row>
      <b-col class="col-12">
        <div>
          <b-link :href="getUrl(content)" target="_blank">
          <b-icon class="icon-type" :icon="`${utils.getContent(content.content_type)}`"/></b-link>
        </div>
        
        <div class="container-interactions">
          <b-avatar class="avatar-style" size="5rem" :src="utils.getImageInfluencer(content.influencer)"/>
          <span class="influencer-username"> {{ content.influencer.name }} 
          <image-verified v-if="content.influencer.is_verified == true"/></span> 
          <b-img v-if="content.influencer.network !== 'twitter'" class="icon-network" :src="`${utils.getImageNetworkColor(content.influencer.network.toLowerCase())}`"/>
          <b-avatar v-else class="icon-network-x">
            <b-img :src="utils.getImageNetworkColor(content.influencer.network.toLowerCase())" class="img-net-x"/>
          </b-avatar>

        <div class="text-center">
          <span>{{ $t('campaigns.contentType') }} {{ getLabelContentType(content) }}</span>
          <span :class="`${utils.getStatusContent(content.status)} margin-status`">{{ $t(`campaigns.${content.status}`) }}</span>
          <span class="text-muted mr-1">{{ $t('campaigns.updated') }}:</span>
          <span >{{`${utils.getDatev2(new Date(content.updated_at))}`}}</span>
       </div>

        
        <div class="col-12 row">
          <div class="mt-1 col-6 col-sm-4 col-lg-6 col-xl-4" v-for="(i, index) in content.metrics" :key="index">
            <div class="d-flex align-items-center justify-content-center">
              <b-icon class="mr-1" :icon="`${utils.getIconInteractions(i.name)}`"/>
              <span>{{ `${utils.getFormat(i.value)}` }}</span>
            </div>
          </div>
        </div>

        </div> 
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol, BIcon, BImg, BAvatar, BLink } from 'bootstrap-vue';
import utils from '@/libs/utils';
import ImageVerified from '@/views/components/image/ImageVerified.vue';

export default {
  name: 'Interactions',
  props: {
   
    index: {
      type: Number,
      default: 0
    },
    content: {
      type: Object,
      required: true
    },
  },
  components: {
    ImageVerified,
    BRow,
    BCol,
    BIcon,
    BImg,
    BAvatar,
    BLink,
  },
  data () {
    return {
      utils,
    }
  },
  methods: {
    getLabelContentType(content) {
      if (content.network === 'tiktok' && content.content_type === 'reel') return 'TikTok'
      if (content.network === 'blog' && content.content_type === 'post') return 'Blog Post'
      if (content.network === 'snapchat' && content.content_type === 'reel') return 'Spotlight'
      return this.$t(`invitation.${content.content_type}`)
    },
    getUrl(content) {
      if (content.image_content) return content.image_content
      return content.url_info
    },
    getImage(influencer) {
      if (influencer.file_for_profile)
        return utils.getAssetUrl(influencer.file_for_profile);
      else 
        return influencer.profile_url;
    },
  },
}
</script>

<style>
.img-net-x {
  width: 1.4em;
  height: 1.4em;
}
.icon-network {
  position: relative;
  width: 25px !important;
  height: 25px !important;
  left: 23px;
  bottom: 48px;
}
.icon-network-x {
  position: relative;
  width: 21px !important;
  height: 21px !important;
  left: 22px;
  bottom: 48px;
  background: black;
}
.container-interactions {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  bottom: 5.4em;
}
.icon-type {
  position: relative;
  bottom: 12em;
  right: -15px;
  font-size: x-large;
  color: white;
}
.icon-edit-content-view {
  cursor: pointer;
  margin-right: 5px;
  float: right;
}
.avatar-style {
  padding: 5px;
  background-color: light;
}
.influencer-username {
  margin-top: 1rem;
  font-weight: bold;
}
.margin-status{
  display: flex;
  justify-content: center;
  margin: 0px 0px 10px 0px !important;
}
</style>

