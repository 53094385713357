<template>
  <b-sidebar
    right
    shadow
    v-model="show"
    backdrop
    class="sidebar-contents"
  >
    <div class="px-3">
      <div class="d-flex justify-content-between">
        <b-media vertical-align="center" class="d-flex justify-content-center align-items-center flex-wrap mb-1 col-6">
          <template #aside>
            <div class="container-avatar-table col-12">
              <b-avatar
                  size="lg"
                  :src="utils.getImageInfluencer(content)"
                  :text="content.username[0]"
                  variant="light-primary"
              />
              <div v-if="content.network_influencer !== 'twitter'" class="container-avatar-table-icon sidebar-icon">
                <span :class="`${utils.getClassNetworkSpan(content.network_influencer)} mr-1 style-border-icon`"/>
              </div>
              <div v-else class="container-avatar-table-icon sidebar-icon">
                <b-img :src="utils.getClassNetworkSpan(content.network_influencer)" class="mr-1 style-border-icon net-image-x-sider"/>
              </div>
            </div>
          </template>
          <div class="col-12">
            <a 
              class="d-block font-weight-bold h4 mb-0 col-12 text-center title-white-sidebar"
              :href="utils.getURL(content.network_influencer, content.username, (content.network_influencer === 'youtube') ? true : false)"
              target="_blank"
            >
              {{ getUsername(content) }}
            </a>
          </div>
        </b-media>  
                
        <b-col class="d-flex flex-wrap justify-content-center col-6">
          <span class="d-block h5 col-12 text-center title-white-sidebar">{{utils.getTypeInfluencer(content.category)}}</span>
          <span class="text-muted col-12 text-center">{{$t('campaigns.type_influencer')}}</span>

          <span class="d-block h5 col-12 text-center mt-2 title-white-sidebar">{{getLabelContentType(content)}}</span>
          <span class="text-muted d-block col-12 text-center">{{$t('briefcase_pricing.content_type')}}</span>
        </b-col>
      </div>
      <hr class="mb-1"/>

      <b-row>
        <b-col class="d-flex flex-wrap justify-content-center col-6 mb-2">
          <b-avatar rounded="lg" variant="light-primary">
            <feather-icon
            size="18"
            icon="LikeIcon"
            />
          </b-avatar>    
          <span class="d-block h5 col-12 text-center mt-1 title-white-sidebar">{{(content.followers)}}</span>
          <span class="text-muted d-block col-12 text-center">{{$t('campaigns.followers')}}</span>
        </b-col>

        <b-col class="d-flex flex-wrap justify-content-center col-6 mb-2">
          <b-avatar rounded="lg" variant="light-primary">
              <feather-icon
              size="18"
              icon="ActivityIcon"
              />
          </b-avatar>    
          <span class="d-block h5 col-12 text-center mt-1 title-white-sidebar">{{content.engagement}} %</span>
          <span class="text-muted d-block col-12 text-center">{{$t('campaigns.erTitle')}}</span>
        </b-col>
      </b-row>

      <hr class="mb-1"/>
      <h4 class="text-center">{{$t('campaigns.metrics_text')}}</h4>
      <div class="col-12 d-flex justify-content-center mt-2" v-if="utils.isUrl(content.content_preview_url) && content.content_type !== 'story'">
        <b-avatar class="img-story" :src="content.content_preview_url"/>
        <b-img class="img-network" :src="`${utils.getImageNetworkColor(content.network)}`"></b-img>
      </div>
      <general-form
        :content="content"
        :uuids="{campaign_uuid, campaign_influencer}"
        :net="content.network"
        @closeModal="updateContent"
        :show_date_publish="true"
      />
    </div>
  </b-sidebar>
</template>

<script>
import { BSidebar, BMedia, BAvatar, BCol, BRow, BImg, } from 'bootstrap-vue';
import utils from '@/libs/utils';
import GeneralForm from '@/views/pages/invitation/newInvitation/forms/GeneralForm.vue';

export default {
  name: 'sidebarContent',
  components: {
    GeneralForm,
    BSidebar,
    BMedia,
    BAvatar,
    BCol,
    BRow,
    BImg,
  },
  props: {
    content_dad: {
      type: Object,
      required: true
    },
    campaign_uuid: {
      type: String,
      required: true
    },
    campaign_influencer: {
      type: String,
      required: true
    },
    show_side: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      utils,
      show:  this.show_side,
      content: { ...this.content_dad}
    }
  },
  methods: {
    getLabelContentType(content) {
      if (content.network === 'tiktok' && content.content_type === 'reel') return 'TikTok'
      if (content.network === 'blog' && content.content_type === 'post') return 'Blog Post'
      if (content.network === 'snapchat' && content.content_type === 'reel') return 'Spotlight'
      return this.$t(`invitation.${content.content_type}`)
    },
    getUsername(influencer) {
      if (influencer.network_influencer === "youtube") return influencer.name;
      return influencer.username;
    },
    updateContent(data) {
      this.$emit('updateFromSidebar', data);
    }
  }
}
</script>

<style>
  .net-image-x-sider {
    width: 1em !important;
    height: 1em !important;
  }
.img-network{
  height: 35px !important;
  width: 35px !important;
  position: relative;
  bottom: -10em;
  right: 3em;
}
.b-sidebar-backdrop {
  background-color: transparent !important;
}
.sidebar-contents > .b-sidebar {
  width: 500px !important;
}

</style>