<template>
  <component :is="tag" v-on="$listeners">
    <div :class="`${padding_component} ${user.type === 'null' ? 'without-badge' : ''} d-flex align-items-center`">
      <b-avatar :src="user.avatar_url" badge-variant="ligth" size="42">
        <template #badge>
          <i v-if="user.type !== 'null' && user.type !== 'twitter'" :class="`${getClassNetworkSpan(user.type)} border-icon`" />
          <b-img v-if="user.type !== 'null' && user.type === 'twitter'" :src="getClassNetworkSpan(user.type)" class="border-icon network-x-chat"/>
        </template>
      </b-avatar>
      <div class="chat-info flex-grow-1">
        <h5 class="mb-0 pl-1">
          {{ user.title }}
          <image-verified
            v-if="user.is_verified"
          />
        </h5>
        <p class="card-text text-truncate pl-1">
          {{ getFormat(user.subscribers_count) }}
        </p>
      </div>
    </div>
  </component>
</template>

<script>
import { BAvatar, BBadge, BIcon, BImg } from 'bootstrap-vue';
import { formatDateToMonthShort } from '@core/utils/filter';
import useChat from './useChat';
import { getFormat } from '@/libs/utils/formats';
import { getClassNetworkSpan } from '@/libs/utils/icons';

export default {
  components: {
    BImg,
    BAvatar,
    BBadge,
    BIcon,
    ImageVerified: () => import('@/views/components/image/ImageVerified.vue'),
  },
  data() {
    return {
      getClassNetworkSpan,
      getFormat,
    };
  },
  props: {
    padding_component: {
      type: String,
      default: 'pb-1',
    },
    tag: {
      type: String,
      default: 'div',
    },
    user: {
      type: Object,
      required: true,
    },
    isChatContact: {
      type: Boolean,
      dedfault: false,
    },
  },
  setup() {
    const { resolveAvatarBadgeVariant } = useChat();
    return { formatDateToMonthShort, resolveAvatarBadgeVariant };
  },
};
</script>

<style>
.border-icon {
  padding: 2px !important;
  font-size: 1.2em !important;
  margin-right: -0.4em !important;
  background: white;
  border-radius: 50%;
}
.network-x-chat {
  width: 1.3em !important;
}
.without-badge > .b-avatar {
  background-color: #fff
}
</style>
