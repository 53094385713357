export const getCategories = (data, key) => {
  return data.map(item => {
    return item[key]
  })
};
export const getSeries = (data, key) => {
  return data.map(item => {
    return item[key]
  })
};
export const getColorSentiment = (sentiment) => {
  const sentiments = [
    {text: 'neutral', color: 'text-warning'},
    {text: 'negative', color: 'text-danger'},
    {text: 'positive', color: 'text-primary'},
  ]

  const element = sentiments.find(item => item.text === sentiment)

  if (element)
    return element.color

  return 'text-info'
};
export const getColorDotProfile = (mark) => {
  const obj = {
    low: 'bg-danger',
    below_average: "bg-danger",
    poor: 'bg-danger',
    fair: 'bg-danger',
    average: 'bg-warning',
    good: 'bg-success',
    very_good: 'bg-success',
    excellent: 'bg-success',
    none: "bg-secondary"
  }
  return obj[mark]
};
export const statusVariant = (status) => {
  const statusColor = {
    onboarding: "light-primary",
    approved: "light-success",
    published: "light-success",
    producing: "light-warning",
    trading: "light-warning",
    submitted: "light-info",
    rejected: "light-danger",
    low: 'light-danger',
    below_average: "light-danger",
    poor: 'light-danger',
    fair: 'light-danger',
    average: 'light-warning',
    good: 'light-success',
    very_good: 'light-success',
    excellent: 'light-success',
    none: "light-secondary"
  };    
  return statusColor[status];
};