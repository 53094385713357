<template>
  <div>
    <div class="main-container-drag-drop d-inline-flex">
      <div class="card-drag-drop" v-for="(colItem, index) in lists" :key="index">
        <h6 class="text-primary font-weight-bold mb-2 d-flex justify-content-center">
          <span :class="`span-rounded ${colItem.value}`" />
          <span>{{ $t(`campaigns.${colItem.value}`) }}</span>
        </h6>

        <component :is="draggable" :newIndex="index" :list="colItem.list" tag="ul" group="people" :id="`draggable-${index}`"
          @change="changeStatus"
          @end="onEnd"
          class="list-group-flush cursor-move draggable-status">
          <b-list-group-item class="m-1 hover-draggable-status" v-for="(listItem, index) in colItem.list" :key="index" tag="li">
            <div class="p-1">
              <b-row class="d-flex align-items-center justify-content-between">
                <div class="font-weight-bold">
                  {{ getUsername(listItem.influencer) }} 
                  <image-verified v-if="listItem.influencer.is_verified === true"/>
                  <span class="text-muted d-block font-size-drag-drop">{{utils.getTypeInfluencer(listItem.influencer.calculate_category).split('/')[0]}}</span>
                </div>
                <b-avatar :src="utils.getImageInfluencer(listItem.influencer)">
                  <template #badge>
                    <i v-if="listItem.influencer.network !== 'twitter'" :class="`${utils.getClassNetworkSpan(listItem.influencer.network.toLowerCase())} border-icon`" />
                    <b-img v-else :src="utils.getClassNetworkSpan(listItem.influencer.network.toLowerCase())" class="border-icon network-x"/>
                  </template>
                </b-avatar>
              </b-row>
              <hr class="mb-1"/>
              <b-row class="d-flex align-items-center justify-content-center font-size-drag-drop">
                <div class="text-center col-6">
                  <content-donut :contents="listItem.contents" :identifier="listItem.uuid" :type_influencer="listItem.influencer.calculate_category" :key="change_drag"/>
                  <span class="d-block text-muted text-center">{{$t('campaigns.contents')}}</span>
                </div>
                <div class="text-center col-6">
                  <span class="h5 d-block text-center">{{shortDate(listItem.updated_at)}}</span>
                  <span class="text-muted">{{$t('campaigns.last_update_short')}}</span>
    
                </div>
              </b-row>
            </div>
          </b-list-group-item>
        </component>
      </div>
    </div>
  </div>
</template>

<script>
import { BRow, BAvatar, BListGroupItem, BImg, } from "bootstrap-vue";
import service from "@/services/campaign";
import utils from "@/libs/utils";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import ContentDonut from "./ContentDonut.vue";
import ImageVerified from '@/views/components/image/ImageVerified.vue';

export default {
  components: {
    BImg,
    ImageVerified,
    BRow,
    BAvatar,
    BListGroupItem,
    ContentDonut,
  },

  data() {
    return {
      utils,
      info: [],
      lists: [
        {
          value: "approved",
          list: [],
        },
        {
          value: "onboarding",
          list: [],
        },
        {
          value: "trading",
          list: [],
        },
        {
          value: "producing",
          list: [],
        },
        {
          value: "submitted",
          list: [],
        },
        {
          value: "published",
          list: [],
        },
        {
          value: "rejected",
          list: [],
        },
      ],
      change_list: [],
      change_drag: false,
      interval_loading: null,
      draggable: null,
    };
  },
  created() {
    this.getData();
  },
  async mounted() {
    this.draggable = (await import('vuedraggable')).default;
  },
  methods: {
    getData() {
      this.$vs.loading({
        type: 'sound',
        background: 'rgba(255, 255, 255, 1)',
        text: utils.getMotivationalPhrase(),
      });

      this.interval_loading = setInterval(() => {
        const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

        if (prev_loading) {
          prev_loading.classList.add('fade-out');
          setTimeout(function() {
            prev_loading.remove()
          }, 500);

          this.$vs.loading({
            type: 'sound',
            background: 'rgba(255, 255, 255, 1)',
            text: utils.getMotivationalPhrase(),
          });
        }
      }, 5000);
      service.getInfluencersCampaign(this.$route.params.uuid).then((response) => {
        this.info = response.results;
        this.orderData();
        clearInterval(this.interval_loading)
        this.$vs.loading.close();
      });
    },
    orderData() {
      this.info.forEach((item) => {
        if (item.status === "approved") this.lists[0].list.push(item);
        else if (item.status === "onboarding") this.lists[1].list.push(item);
        else if (item.status === "trading") this.lists[2].list.push(item);
        else if (item.status === "producing") this.lists[3].list.push(item);
        else if (item.status === "submitted") this.lists[4].list.push(item);
        else if (item.status === "published") this.lists[5].list.push(item);
        else if (item.status === "rejected") this.lists[6].list.push(item);
      });
    },
    getUsername(influencer) {
      if (influencer.network === "youtube") return influencer.name;
      return influencer.username;
    },
    onEnd(value) {
      this.change_list = [value.from.id.split('-')[1],value.to.id.split('-')[1]];
    },
    changeStatus(value) {
      setTimeout(() => {
        this.change_drag = !this.change_drag;
        if (this.change_list[0] !== this.change_list[1]) {
          if (value.added) {
            const obj = {
              influencers: [value.added.element.uuid],
              status: this.lists[this.change_list[1]].value
            }
            service.deleteCreator(this.$route.params.uuid, obj).then((response) => {
              this.$emit('update_header', response.results[0].campaign_header);
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('campaigns.success_status'),
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                }
              });
            }).catch((err) => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('campaigns.error_status'),
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: this.$t('campaigns.error_status_text') + err
                }
              });
            })
          }
        }
      },100)
    },
    shortDate(date) {
      const newDate = new Date(date);
      const options = { year: "numeric", month: "numeric", day: "numeric" };
      return newDate.toLocaleDateString("es-ES", options);
    },
  },
};
</script>

<style>
.border-icon {
  font-size:1.1em; 
  border-radius: 50%; 
  background: white; 
  padding: 2px;
}
.network-x {
  width: 1.3em !important;
}
.span-rounded {
  display: block;
  width: 5px;
  padding: 0.5em;
  background-color: red;
  border-radius: 360px;
  float: left;
  margin-right: 1em;
  color: white;
}
.approved, .published{
  background-color: #2FC974;
}
.onboarding {
  background-color: #7367F0;
}
.trading, .producing {
  background-color: #FFA249;
}
.submitted {
  background-color: #0096fe;
}
.rejected {
  background-color: red;
}
.badge-primary {
  background-color: transparent;
}
.draggable-status {
  height: 55em;
  overflow: auto;
}
.draggable-status::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px !important;
  height: 7px;
}
.font-size-drag-drop, .font-size-drag-drop .h5 {
  font-size: 12.5px;
}
.main-container-drag-drop::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px !important;
  height: 7px;
}
.main-container-drag-drop {
  overflow-x: scroll;
  width: 100%;
  height: 500px;
}
.card-drag-drop {
  min-width: 250px;
  width: 250px !important;
  margin: 0.5em;
}
.hover-draggable-status:hover{
  background-color: white;
  transform: scale(1.05);
  transition: all 500ms;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}
</style>